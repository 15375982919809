<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Survey for quotation: #{{ quotation.jobNumber }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-8">
        <h2 class="large-heading pb-4">Works Survey</h2>
        <h4>Date: {{ survey.prettyDate }}</h4>
        <p class="pt-2">Customer reference: {{ survey.customer_reference }}</p>
        <p>
          Work ares:
          <span v-if="survey.internal">Internal</span>
          <span v-if="survey.external">External</span>
        </p>
      </v-col>
    </v-row>
    <v-row :key="index" v-for="(section, index) in surveySections">
      <v-col class="col-4">
        <p>Item #{{ (index + 1) }}</p>
        <p>{{ section.title}}</p>
        <p>{{ section.description }}</p>
      </v-col>
      <v-col class="col-8">
        <v-row>
          <v-col
              class="col-3"
              :key="findex"
              v-for="(file, findex) in section.files">
            <img :src="file.uri" alt="" />
            <label>
              Show to crews
              <v-simple-checkbox
                  style="display:inline; position:relative; top: 3px"
                  v-model="file.show_to_crews"></v-simple-checkbox>
            </label>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12">
        <hr class="mt-4" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsViewSurvey',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      customerName: '',
      quotation: {
        contact_id: 0,
        postCode:'',
        purchase_order: '',
        purchase_order_uri: '',
      },
      survey: {},
      surveySections: [],
      message: '',
      showMessage: false,
    };
  },
  watch: {
    surveySections: {
      handler() {
        this.updateShowToCrews();
      },
      deep: true
    }
  },
  methods: {
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
        this.getSurvey();
      });
    },
    getSurvey() {
      axios.get(`/siteSurveys/getOne/${this.quotation.site_survey_id}.json?token=${this.token}`)
      .then((response) => {
        this.survey = response.data.survey;
        this.getSurveySections();
      });
    },
    getSurveySections() {
      axios.get(`/siteSurveySections/get/${this.survey.id}.json?token=${this.token}`)
        .then((response) => {
          this.surveySections = response.data.sections;
        });
    },
    updateShowToCrews() {
      axios.post(`/filesSiteSurveySections/updateShowToCrews.json?token=${this.token}`, this.surveySections);
    }
  },
  mounted() {
    this.getQuotation();
  },
};
</script>
